/* eslint-disable class-methods-use-this */
/**
 * Licent
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { ApplicationController, useDebounce } from 'stimulus-use';
import * as Turbo from '@hotwired/turbo';

const { mapkit } = window;

/**
 * Controller for establishments.
 */
export default class extends ApplicationController {
  /**
   * @return {Array}
   */
  static targets = ['query', 'loader', 'list', 'establishment', 'map'];

  /**
  * @return {Array}
  */
  static classes = ['active', 'wrapactive'];

  /**
   * @return {Object}
   */
  static values = {
    url: String,
  };

  /**
   * @return {Array}
   */
  static debounces = ['search'];

  /**
   * @var {Map}
   */
  map;

  /**
   * @var {Region}
   */
  region;

  /**
   * @var {Array}
   */
  annotations = [];

  /**
   * @var {Boolean}
   */
  filtering = false;

  /**
   * Initialize map.
   */
  initialize() {
    mapkit.init({
      authorizationCallback(done) {
        done(import.meta.env.VITE_MAPKIT_TOKEN);
      },
    });
  }

  /**
   * Connect
   */
  connect() {
    useDebounce(this);
  }

  /**
   * Connect map.
   *
   * @param {HTMLElement} target
   */
  mapTargetConnected(target) {
    this.map = new mapkit.Map(target);
  }

  /**
   * Destroy map.
   */
  mapTargetDisconnected() {
    if (this.map !== undefined) {
      this.map.destroy();
    }
  }

  /**
   * Set markers for list.
   *
   * @param {HTMLElement} target
   */
  listTargetConnected(target) {
    if (!this.filtering) {
      const url = new URL(target.dataset.url);
      this.setMarkers(url);
    }
  }

  /**
   * Search.
   *
   * @param {Event} e
   */
  search(e) {
    this.filtering = false;
    const url = new URL(e.target.form.action);
    url.searchParams.set(e.target.name, e.target.value);
    Turbo.visit(url.toString(), { frame: 'establishments' });
  }

  /**
   * Geocode.
   */
  async geocode() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove('hidden');
      this.loaderTarget.classList.add('flex');
    }

    // Get current GPS position
    const position = await this.getPosition();

    // Send coords to server to retrieve current country
    const coordinates = new mapkit.Coordinate(position.coords.latitude, position.coords.longitude);
    const address = await this.getAddress(coordinates);

    // Set address in search field and search
    this.queryTarget.value = address?.results[0]?.locality;
    this.search({ target: this.queryTarget });

    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove('flex');
      this.loaderTarget.classList.add('hidden');
    }
  }

  /**
   * Get position.
   *
   * @return {Promise}
   */
  getPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  /**
   * Get address from coordinates.
   *
   * @param {Coordinate} coordinates
   *
   * @return {Promise}
   */
  getAddress(coordinates) {
    const geocoder = new mapkit.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.reverseLookup(coordinates, (error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }

  /**
   * Render markers.
   *
   * @param {URL} url
   */
  async setMarkers(url) {
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove('hidden');
      this.loaderTarget.classList.add('flex');
    }

    // Get markers
    const response = await fetch(url);
    const establishments = await response.json();

    // Set markers
    this.map.removeItems(this.annotations);
    this.annotations = [];
    for (let i = 0; i < establishments.length; i += 1) {
      const establishment = establishments[i];
      const coords = new mapkit.Coordinate(establishment.latitude, establishment.longitude);

      const annotation = new mapkit.Annotation(coords, this.getMarker, {
        data: { ...establishment },
        animates: false,
        selected: url.searchParams.has('id'),
      });

      if (this.hasActiveClass) {
        annotation.addEventListener('select', (e) => {
          this.annotations.forEach((a) => {
            a.element.classList.toggle(this.activeClass, a.data.id === e.target.data.id);
          });

          this.establishmentTargets.forEach((es) => {
            es.closest('[data-wrap]')?.classList.add(this.wrapactiveClass);
            es.classList.toggle(this.activeClass, +es.dataset.id === e.target.data.id);
          });

          const span = new mapkit.CoordinateSpan(0.01);
          const region = new mapkit.CoordinateRegion(e.target.coordinate, span);
          this.map.setRegionAnimated(region);

          // Filter by label, if any
          if (e.target.data.lid) {
            this.filtering = window.location.href;
            const select = new URL(this.filtering);
            select.searchParams.set('q', e.target.data.subtitle);
            select.searchParams.set('l', e.target.data.lid);
            Turbo.visit(select.toString(), { frame: 'establishments' });
          }
        });

        annotation.addEventListener('deselect', () => {
          this.annotations.forEach((a) => {
            a.element.classList.remove(this.activeClass);
          });

          this.establishmentTargets.forEach((es) => {
            es.classList.remove(this.activeClass);
          });

          this.map.setRegionAnimated(this.region);

          if (this.filtering) {
            Turbo.visit(this.filtering, { frame: 'establishments' });
            this.filtering = false;
          }
        });
      }

      this.annotations.push(annotation);
    }
    this.map.showItems(this.annotations);

    // Cache original region
    this.region = this.map.region;

    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove('flex');
      this.loaderTarget.classList.add('hidden');
    }
  }

  /**
   * Create marker.
   *
   * @return {Element}
   */
  getMarker() {
    const element = document.createElement('div');
    element.className = 'establishment-marker';

    return element;
  }

  /**
   * Toggle target class.
   *
   * @param {Event} e
   */
  toggle(e) {
    const target = e.target.closest('[data-id]');
    const wrap = e.target.closest('[data-wrap]');
    const active = target.classList.toggle(this.activeClass);
    wrap?.classList.toggle(this.wrapactiveClass);

    this.annotations.forEach((a) => {
      a.element.classList.toggle(
        this.activeClass,
        JSON.parse(target.dataset.id).includes(a.data.id) && active,
      );

      if (JSON.parse(target.dataset.id).includes(a.data.id)) {
        if (active) {
          const span = new mapkit.CoordinateSpan(0.01);
          const region = new mapkit.CoordinateRegion(a.coordinate, span);
          this.map.setRegionAnimated(region);
        } else {
          this.map.setRegionAnimated(this.region);
        }
      }
    });
  }

  /**
   * Hover target class.
   *
   * @param {Event} e
   */
  hover(e) {
    const target = e.target.closest('[data-id]');
    const active = e.type === 'mouseenter' || target.classList.contains(this.activeClass);

    this.annotations.forEach((a) => {
      a.element.classList.toggle(
        this.activeClass,
        JSON.parse(target.dataset.id).includes(a.data.id) && active,
      );
    });
  }
}
